<template>
  <bkf-widget
    class="widget-device-shift-work"
  >
    <template slot="title">
      {{ 'widget.device-shift-work.title'|trans }}
    </template>
    <template slot="actions">
      <v-menu
        left
        min-width="250"
      >
        <template #activator="{ on: menu, attrs }">
          <v-tooltip
            bottom
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>sort</v-icon>
              </v-btn>
            </template>
            <span> {{ 'widget.device-shift-work.sort.sort'|trans }}</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item @click="changeSort('work','asc')">
            <v-list-item-title>
              {{ 'widget.device-shift-work.sort.work-asc'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeSort('active','asc')">
            <v-list-item-title>
              {{ 'widget.device-shift-work.sort.active-asc'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeSort('name','asc')">
            <v-list-item-title>
              {{ 'widget.device-shift-work.sort.name-asc'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeSort('work','desc')">
            <v-list-item-title>
              {{ 'widget.device-shift-work.sort.work-desc'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeSort('active','desc')">
            <v-list-item-title>
              {{ 'widget.device-shift-work.sort.active-desc'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeSort('name','desc')">
            <v-list-item-title>
              {{ 'widget.device-shift-work.sort.name-desc'|trans }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        left
        min-width="250"
      >
        <template #activator="{ on: menu, attrs }">
          <v-tooltip
            bottom
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <span> {{ 'action-button.more'|trans }}</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item @click="openSettings">
            <v-list-item-icon>
              <v-icon>
                settings
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ 'widget.settings'|trans }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <div
      v-if="loading"
      class="progress"
    >
      <v-progress-circular
        :size="60"
        color="primary"
        indeterminate
      />
    </div>
    <div
      :class="{'onMobile': isMobile}"
    >
      <interval
        class="datepicker mb-10"
        :editable="!loading"
        :clearable="false"
        placeholder=""
        type="date"
        :value="date"
        @input="setDate"
      />
    </div>
    <div class="widget-content">
      <chart
        v-if="checkIfEmptyData"
        :data="devices"
        :counters="counters"
        :settings="settings"
      />
      <bkf-empty-state
        v-else
        :show-widget="false"
        :show-button="false"
      />
    </div>
    <settings
      v-if="initialized"
      ref="settings"
      v-model="settings"
      :device-groups="deviceGroups"
      @submit="saveSettings"
    />
    <bkf-refresher
      :speed="60"
      :pause="loading"
      @refresh="refresh"
    />
  </bkf-widget>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import { format, startOfDay, isToday } from 'date-fns';
import { propEq, all } from 'ramda';
import guideMixin from '@mixins/misc/guide';
import settingsMixin from '@mixins/misc/settings';
import sizeMixin from '@mixins/store/size';
import Settings from './Settings.vue';
import guide from './guide';
import Chart from './Chart.vue';
import Interval from './Interval.vue';

export default {
  components: {
    Chart,
    Settings,
    Interval,
  },
  mixins: [
    guideMixin,
    settingsMixin,
    sizeMixin,
  ],
  props: {
    deviceGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date: startOfDay(new Date()),
      initialized: false,
      loading: true,
      avg: 0,
      settings: {
        deviceGroups: [],
      },
      selectedDeviceGroups: [],
      sort: {
        by: 'work',
        dir: 'desc',
      },
      show: {
        devicesWork: true,
        averageDevicesWork: true,
        devicesActive: true,
        averageDevicesActive: true,
        deviceCounter: false,
        avergeDeviceCounter: false,
      },
      devices: [],
      counters: [],
    };
  },
  computed: {
    checkIfEmptyData() {
      const zeros = propEq('activeSum', 0);
      return !all(zeros)(this.devices);
    },
  },
  async created() {
    // Try to load settings from backend
    const settings = await this.getSetting('device-shift-work');
    this.settings = settings || this.settings;
    this.show = this.settings.show || this.show;
    this.settings.show = this.show;
    this.sort = this.settings.sort || this.sort;
    this.settings.sort = this.sort;

    // get the data
    await this.fetchData();

    this.registerGuide(guide);
    this.initialized = true;
    this.loading = false;
  },
  mounted() {
    this.$nextTick(() => {
      this.detectComponetResize();
    });
  },
  updated() {
    this.detectComponetResize();
  },
  methods: {
    openSettings() {
      this.$refs.settings.open();
    },
    async saveSettings() {
      this.settings.sort = this.sort;
      await this.setSetting('device-shift-work', this.settings);

      if (this.selectedDeviceGroups !== this.settings.deviceGroups) {
        await this.fetchData();
      }
    },
    setDate(date) {
      this.date = date;
      this.fetchData();
    },
    async fetchData() {
      const { date } = this;
      const { deviceGroups } = this.settings;
      this.selectedDeviceGroups = deviceGroups;

      this.loading = true;

      const payload = {
        groups: deviceGroups.length === 0 ? null : deviceGroups.join(','),
        from: format(date, 'YYYY-MM-DD'),
        to: format(date, 'YYYY-MM-DD'),
        type: 'device-work-device-shift',
      };

      const response = await this.$http.get('/reports/factory/data', payload);

      if (response.status === 200 && response.data.data) {
        this.devices = response.data.data;
      }

      this.loading = false;
    },
    async refresh() {
      if (isToday(this.date)) {
        await this.fetchData();
      }
    },
    detectComponetResize() {
      const resizeObserver = new ResizeObserver((items) => {
        this.$emit('resize-masonry-item', items[0].target);
      });
      if (this.$el instanceof Element) {
        resizeObserver.observe(document.querySelector('.widget-device-shift-work'));
      }
    },
    changeSort(by, dir) {
      this.sort.by = by;
      this.sort.dir = dir;
      this.saveSettings();
    },
    isSorted(by, dir) {
      return (this.sort.by === by && this.sort.dir === dir);
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  position: absolute
  width: 96%;
  height: 79%;
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
  z-index: 100
.onMobile
  display: flex
  flex-direction: column
  align-items: center
.selected
 color: #2196f3
</style>
