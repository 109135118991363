<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <v-menu
          left
          min-width="250"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="!$vuetify.breakpoint.mobile"
              tile
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ 'diagnostics.add-diagnostics'|trans }}
            </v-btn>
            <v-btn
              v-else
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="create('monitor')">
              <v-list-item-title>
                {{ 'diagnostics.add-monitor'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="create('deviceOperatorMonitor')">
              <v-list-item-title>
                {{ 'diagnostics.add-device-operator-monitor'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="create('deviceWorkOperatorMonitor')">
              <v-list-item-title>
                {{ 'diagnostics.title-work-activity'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="create('geoMonitor')">
              <v-list-item-title>
                {{ 'diagnostics.add-geomonitor'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="create('efficiencyMonitor')">
              <v-list-item-title>
                {{ 'diagnostics.add-efficiency-monitor'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="create('asyncMonitor')">
              <v-list-item-title>
                {{ 'diagnostics.add-async-monitor'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="create('deviceWorkShiftMonitor')">
              <v-list-item-title>
                {{ 'diagnostics.title-work-activity-shift'|trans }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-3"
      >
        <div class="flex flex-wrap flex-justify-space-between">
          <template v-for="monitor in settings.monitors">
            <bkf-monitor
              v-if="monitor.type === 'monitor'"
              :key="monitor.id"
              :devices="devices"
              :device-groups="deviceGroups"
              :device-types="deviceTypes"
              :monitor="monitor"
              :shifts="shifts"
              @remove="remove"
              @save="save"
            />
            <bkf-device-operator-monitor
              v-if="monitor.type === 'deviceOperatorMonitor'"
              :key="monitor.id"
              :device-operators="deviceOperators"
              :device-operators-groups="deviceOperatorsGroups"
              :monitor="monitor"
              @remove="remove"
              @save="save"
            />
            <bkf-device-work-operator-monitor
              v-if="monitor.type === 'deviceWorkOperatorMonitor'"
              :key="monitor.id"
              :devices="devices"
              :device-groups="deviceGroups"
              :monitor="monitor"
              @remove="remove"
              @save="save"
            />
            <bkf-geo-monitor
              v-if="monitor.type === 'geoMonitor'"
              :key="monitor.id"
              :devices="devices"
              :device-groups="deviceGroups"
              :monitor="monitor"
              @remove="remove"
              @save="save"
            />
            <bkf-efficiency-monitor
              v-if="monitor.type === 'efficiencyMonitor'"
              :key="monitor.id"
              :devices="devices"
              :device-groups="deviceGroups"
              :device-types="deviceTypes"
              :monitor="monitor"
              :shifts="shifts"
              @remove="remove"
              @save="save"
            />
            <bkf-async-monitor
              v-if="monitor.type === 'asyncMonitor'"
              :key="monitor.id"
              :devices="devices"
              :device-types="deviceTypes"
              :monitor="monitor"
              @remove="remove"
              @save="save"
            />
            <bkf-device-work-shift-monitor
              v-if="monitor.type === 'deviceWorkShiftMonitor'"
              :key="monitor.id"
              :devices="devices"
              :device-groups="deviceGroups"
              :monitor="monitor"
              @remove="remove"
              @save="save"
            />
          </template>
          <bkf-add-monitors
            @click="create"
          />
        </div>
      </bkf-page>
      <bkf-page-settings
        ref="pageSettings"
        :settings.sync="settings"
      />
    </v-col>
  </v-row>
</template>

<script>
import { filter } from 'ramda';
import pageMixin from '@mixins/page';
import settingsMixin from '@mixins/misc/settings';

export default {
  mixins: [
    pageMixin,
    settingsMixin,
  ],
  data() {
    return {
      devices: [],
      deviceOperators: [],
      deviceOperatorsGroups: [],
      deviceGroups: [],
      deviceTypes: [],
      shifts: [],
      settings: {
        monitors: [],
      },
      settingsUrl: '/users/current/settings',
    };
  },
  async created() {
    const userSettings = await this.getSetting('user-settings');

    if (userSettings) {
      this.shifts = userSettings.shifts;
    }
  },
  methods: {
    async onActivatePage() {
      const Device = await this.$model('Device');
      const DeviceGroup = await this.$model('DeviceGroup');
      const DeviceType = await this.$model('DeviceType');
      const DeviceOperator = await this.$model('DeviceOperator');
      const DeviceOperatorGroup = await this.$model('DeviceOperatorGroup');

      const [devices, deviceGroups, deviceTypes, deviceOperators, deviceOperatorsGroups] = await
      Promise.all([
        Device.query({
          contexts: ['devices_monitor'],
          sort: { name: 'asc' },
        }),
        DeviceGroup.query({
          contexts: ['devicegroups_list'],
          sort: { name: 'asc' },
        }),
        DeviceType.query({
          contexts: ['devicetypes_monitor'],
          sort: { name: 'asc' },
        }),
        DeviceOperator.query({
          sort: { name: 'asc' },
        }),
        DeviceOperatorGroup.query({
          sort: { name: 'asc' },
        }),
      ]);

      this.devices = devices.entries;
      this.deviceGroups = deviceGroups.entries;
      this.deviceTypes = deviceTypes.entries;
      this.deviceOperators = deviceOperators.entries.sort(
        (a, b) => a.name.localeCompare(b.name, 'pl'),
      );
      this.deviceOperatorsGroups = deviceOperatorsGroups.entries.sort(
        (a, b) => a.name.localeCompare(b.name, 'pl'),
      );
    },
    create(type) {
      this.settings.monitors.push({
        id: String(Date.now()),
        type,
      });
    },
    remove(monitor) {
      const { settings } = this;
      settings.monitors = filter((m) => m.id !== monitor.id, settings.monitors);
    },
    save() {
      this.$refs.pageSettings.save(this.settings);
    },
  },
};
</script>

<style scoped lang="stylus">
.add-chart
  align-items: center
  justify-content: center
</style>
