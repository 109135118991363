<template>
  <report
    :report-type="reportType"
    :group-type="groupType"
  >
    <template #groupItemTable="{ items, groupindex }">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>
                {{ 'shifts.shift'|trans }}
              </th>
              <th>
                {{ 'shifts.start'|trans }}
              </th>
              <th>
                {{ 'shifts.stop'|trans }}
              </th>
              <th class="text-right">
                {{ 'report.field.worktime'|trans }}
              </th>
              <th class="text-right">
                {{ 'report.field.activitytime'|trans }}
              </th>
              <th class="text-right">
                {{ 'report.field.counter'|trans }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="`${reportType}-item-${groupindex}-${index}`"
            >
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.startTime|dateTime }}
              </td>
              <td>
                {{ item.endTime|dateTime }}
              </td>
              <td class="text-right">
                {{ formatTime(item.workSum) }}
              </td>
              <td class="text-right">
                {{ formatTime(item.activeSum) }}
              </td>
              <td class="text-right">
                {{ item.counterSum }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </report>
</template>

<script>
import { format } from 'date-fns';
import Report from '../../components/report';

export default {
  components: {
    Report,
  },
  data() {
    return {
      groupType: 'deviceGroup',
      reportType: 'device-work-shift-chart',
    };
  },
  methods: {
    formatTime(seconds) {
      return format(new Date(seconds * 1000), 'HH:mm:ss');
    },
  },
};
</script>
