<template>
  <v-autocomplete
    :value="value"
    :items="parameters"
    :disabled="disabled"
    :multiple="true"
    chips
    small-chips
    deletable-chips
    :item-text="getItemText"
    item-value="number"
    :label="$trans('monitor.parameter')"
    @change="emitInput"
  />
</template>

<script>
import parameterTrans from '@mixins/misc/parameterTrans';

export default {
  mixins: [parameterTrans],
  props: {
    label: {
      required: true,
      type: String,
    },
    disabled: {
      required: true,
      type: Boolean,
      default: true,
    },
    parameters: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Array,
    },
  },
  methods: {
    emitInput(number) {
      this.$emit('input', number);
    },
    getItemText(item) {
      return item.name;
    },
  },
};
</script>
