<template>
  <bkf-widget
    class="monitor"
    :class="monitorClass"
  >
    <template slot="title">
      <template v-if="showTitle">
        {{ 'diagnostics.title-efficiency-monitor'|trans }}
      </template>
    </template>
    <template slot="actions">
      <template v-if="showActions">
        <template v-if="settings.edited">
          <bkf-action-close @click="remove" />
          <bkf-action-expand
            v-if="!isMobile"
            v-model="settings.expanded"
            @click="resize"
          />
          <bkf-action-refresh @click="refresh" />
        </template>
        <bkf-action-options
          @click="onOptions(!settings.edited)"
        />
      </template>
    </template>
    <transition name="fade">
      <div
        v-if="loading"
        class="progress"
      >
        <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
        />
      </div>
    </transition>
    <template v-if="settings.edited">
      <div class="mh-5">
        <template v-if="showDevices">
          <v-radio-group
            v-model="settings.byGroups"
            :mandatory="true"
            :hide-details="true"
            row
            dense
            class="mb-4 mt-2"
          >
            <v-radio
              :label="$trans('monitor.devices')"
              :value="false"
            />
            <v-radio
              :label="$trans('monitor.device-groups')"
              :value="true"
            />
          </v-radio-group>
          <field-devices
            v-show="!settings.byGroups"
            v-model="settings.devices"
            :devices="devicesWithSameType"
          />
          <field-device-groups
            v-show="settings.byGroups"
            v-model="settings.deviceGroups"
            :device-groups="deviceGroups"
          />
        </template>
      </div>
      <div
        class="flex flex-wrap"
      >
        <div class="flex-1 mh-5 wmin-250">
          <field-parameter
            v-model="settings.parameters"
            :label="fieldParameterLabel"
            :disabled="fieldParameterDisabled"
            :parameters="selectableParameters"
          />
        </div>
        <div class="flex-1 mh-5 wmin-250">
          <field-date-range v-model="settings.dateRange" />
        </div>
        <div class="flex-1 mh-5 wmin-250">
          <field-interval
            v-model="settings.interval"
            :shifts="shifts"
            :check-date-range="checkDateRange"
          />
        </div>
      </div>
    </template>
    <div class="chart-container">
      <v-alert
        v-if="message"
        type="warning"
        class="mt-0"
      >
        {{ message }}
      </v-alert>
      <chart
        v-if="settingsValid && render"
        ref="chart"
        :options="echart"
        :auto-resize="true"
      />
    </div>
    <bkf-refresher
      :speed="300"
      :pause="loading"
      @refresh="refresh"
    />
  </bkf-widget>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts.vue';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/toolbox';

// Date functions
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfToday from 'date-fns/start_of_today';

// Ramda function
import {
  flatten, findIndex, max, sortBy, prop, propEq, uniq, repeat, reduce, toLower, compose,
} from 'ramda';

import menuMixin from '@mixins/store/menu';
import sizeMixin from '@mixins/store/size';
import csv from '@utils/csv';
import { truthyAll } from '@utils/asserts';
import human from '@utils/human';

import parameterTrans from '@mixins/misc/parameterTrans';
import FieldDateRange from './fields/DateRange';
import FieldDevices from './fields/Devices';
import FieldDeviceGroups from './fields/DeviceGroups';
import FieldInterval from './fields/Interval';
import FieldParameter from './fields/Parameter';

import colors, { swatches } from './internals/colors';
import intervals from './internals/intervals';
import echartsConfig from './internals/echarts';

export default {
  components: {
    chart: ECharts,
    FieldDateRange,
    FieldDevices,
    FieldDeviceGroups,
    FieldInterval,
    FieldParameter,
  },
  mixins: [
    menuMixin,
    sizeMixin,
    parameterTrans,
    echartsConfig,
  ],
  props: {
    monitor: {
      required: true,
      type: Object,
    },
    devices: {
      required: true,
      type: Array,
    },
    deviceGroups: {
      required: true,
      type: Array,
    },
    deviceTypes: {
      required: true,
      type: Array,
    },
    shifts: {
      required: false,
      type: Array,
      default: () => [],
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true,
    },
    showActions: {
      required: false,
      type: Boolean,
      default: true,
    },
    showDevices: {
      required: false,
      type: Boolean,
      default: true,
    },
    expanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    edited: {
      required: false,
      type: Boolean,
      default: true,
    },
    width: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      render: true,
      loading: false,
      settings: {
        devices: [],
        deviceGroups: [],
        parameter: null,
        parameters: [],
        interval: intervals[1],
        dateRange: [
          startOfToday(),
          startOfToday(),
        ],
        expanded: false,
        edited: true,
        byGroups: false,
      },
      unit: {
        short: null,
        long: null,
        original_short: null,
        original_long: null,
        min: null,
        max: null,
        shift: t`shifts.shift`,
      },
      units: [],
      date: {
        format: 'YYYY-MM-DD',
      },
      echart: {
        grid: {
          top: 80,
          right: 90,
          left: 60,
          bottom: 20,
          containLabel: true,
        },
        toolbox: {
          show: true,
          itemGap: 10,
          itemSize: 20,
          feature: {
            restore: {
              title: t`chart.restore`,
            },
            dataZoom: {
              title: {
                zoom: t`chart.area-zooming`,
                back: t`chart.restore-area-zooming`,
              },
              yAxisIndex: 'none',
            },
            saveAsImage: {
              title: t`chart.save-as-image`,
            },
            mySaveAsCSV: {
              show: true,
              title: t`chart.save-as-csv`,
              icon: 'path://M0,0h10l4,4v16h-14v-20M10,0v4h4M4,8l6,8m0,-8l-6,8',
              onclick: this.toCSV,
            },
            magicType: {
              type: ['line', 'bar'],
              title: {
                line: t`chart.line-chart`,
                bar: t`chart.bar-chart`,
              },
              option: {
                bar: {
                  animationEasing: 'elasticOut',
                  animationDelay: (idx) => idx * 10,
                },
                line: {
                  animationEasing: 'linear',
                },
              },
            },
          },
        },
        legend: {
          data: [],
          top: 40,
          type: 'scroll',
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [],
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }],
        series: [],
        animationDuration: 300,
        color: colors,
      },
    };
  },
  computed: {
    checkDateRange() {
      const { dateRange } = this.settings;
      const from = format(dateRange[0], 'YYYY-MM-DD');
      const to = format(dateRange[1], 'YYYY-MM-DD');

      if (from < to) {
        return false;
      }

      return true;
    },
    monitorClass() {
      const { isMobile, settings } = this;

      if (isMobile || settings.expanded) {
        return ['widget-expanded'];
      }

      return '';
    },
    // Dynamicaly computes selected Devices by mapping devices ids (this.settings.devices)
    // To Devices entries (from this.devices array)
    selectedDevices() {
      const { devices, deviceGroups, byGroups } = this.settings;

      if (byGroups) {
        const results = this.deviceGroups
          .filter((deviceGroup) => deviceGroups.includes(deviceGroup.id))
          .map((deviceGroup) => deviceGroup.devices.map((device) => device.id));
        const filtered = uniq(flatten(results));

        return this.devices.filter((device) => filtered.includes(device.id));
      }

      return this.devices.filter((device) => devices.includes(device.id));
    },
    devicesWithSameType() {
      const { selectedDevices, devices } = this;
      if (selectedDevices.length) {
        const firstType = selectedDevices[0].deviceType.id;
        return this.devices.filter(
          (device) => device.deviceType.id === firstType,
        );
      }
      return devices;
    },
    // Flatten and make unique array of parameters ids of all selected Devices
    selectableParameters() {
      const effectiveWork = 'effective work time';
      const deviceActive = 'device active';
      const efficiencyParameter = {
        number: 0,
        name: this.translateParameterNameByTag(['efficiency']),
        tags: ['efficiency'],
      };
      const { deviceTypes, selectedDevices } = this;
      const selectedDeviceTypesIds = uniq(selectedDevices.map((device) => device.deviceType.id));
      const selectedDeviceTypes = deviceTypes
        .filter((deviceType) => selectedDeviceTypesIds.includes(deviceType.id));
      const parameters = sortBy(
        compose(toLower, prop('name')),
        uniq(flatten(selectedDeviceTypes.map((deviceType) => deviceType.parameters))),
      );
      let filtered = parameters.filter(
        (p) => p.tags.includes(effectiveWork) || p.tags.includes(deviceActive),
      );

      filtered = filtered.map(
        (p) => ({
          number: p.number,
          name: this.translateParameterNameByTag(p.tags),
          tags: p.tags,
        }),
      );
      if (filtered.length > 1) {
        filtered.push(efficiencyParameter);
      }
      return filtered;
    },
    selectedEfficiencyParameter() {
      return this.selectableParameters.filter((p) => p.number !== 0).map((p) => p.number);
    },
    // Find work parameter in selectable parameters
    selectableWorkParameter() {
      const { selectableParameters } = this;
      const tagName = 'effective work time';

      return selectableParameters.find((p) => p.tags.includes(tagName)) || null;
    },
    selectableActiveParameter() {
      const { selectableParameters } = this;
      const tagName = 'device active';

      return selectableParameters.find((p) => p.tags.includes(tagName)) || null;
    },
    // Computes if Parameter field should be disabled
    fieldParameterDisabled() {
      return this.selectedDevices.length === 0;
    },
    // Computes label for Parameter field
    fieldParameterLabel() {
      if (this.selectedDevices.length) {
        return t`monitor.parameter`;
      }

      return t`monitor.select-device-at-first`;
    },
    // Settings validation state
    settingsValid() {
      const { selectedDevices, shifts } = this;
      const { interval, dateRange, parameters } = this.settings;

      if (interval && interval.id === 'shifts' && shifts.length === 0) {
        return false;
      }

      return truthyAll(
        dateRange[0],
        dateRange[1],
        interval,
        parameters.length,
        selectedDevices.length,
      );
    },
    message() {
      const { settings } = this;
      if (!settings.devices.length && !settings.byGroups) {
        return t`monitor.message.no-devices`;
      }

      if (!settings.deviceGroups.length && settings.byGroups) {
        return t`monitor.message.no-device-groups`;
      }

      if (!this.selectableParameters.length) {
        if (this.selectedDevices.length > 1) {
          return t`monitor.message.no-common-parameters`;
        }

        return t`monitor.message.no-parameters`;
      }

      if (this.selectableParameters.length && !settings.parameters.length) {
        return t`monitor.message.no-parameter`;
      }

      if (settings.interval && settings.interval.id === 'shifts' && !this.shifts.length) {
        return t`monitor.message.no-shifts-defined`;
      }

      return '';
    },
  },
  watch: {
    // Watch paramsState save settings when parameters are valid
    settings: {
      deep: true,
      handler(to, from) {
        const { selectableParameters } = this;

        // Check if device select mode was changed
        if (to.byGroups !== from.byGroups) {
          this.saveSettings();
          return;
        }

        // Check if parameter is selectable
        if (to.parameter && !selectableParameters.includes(to.parameter)) {
          this.settings.parameter = null;
          return;
        }

        if (to.parameters.length > 1 && to.parameters.includes(0)) {
          this.settings.parameters = [0];
        }
        // Save settings and resize chart
        this.saveSettings();
        this.resize();

        // Edit or expand mode was changed so not fetch Data
        if (to.edited !== from.edited || to.expanded !== from.expanded) {
          return;
        }

        if (this.settingsValid) {
          this.fetchData();
        }
      },
    },
    // Watch width value (if passed) and if its changed, then resize chart
    width(value) {
      this.resize(value);
    },
    // Resize if navigation menu is toggled
    menuVisible() {
      this.resize();
    },
    // Watch selectable Parameters and if they are changed
    // try to select efficiency then first one
    async selectableParameters() {
      const { selectableWorkParameter, selectableParameters, settings } = this;
      const { parameter } = settings;

      settings.parameters = settings.parameters.filter((param) => findIndex(propEq('number', param))(selectableParameters) !== -1);

      if (parameter && selectableParameters.includes(parameter)) {
        return;
      }

      if (selectableWorkParameter && settings.parameters.length === 0) {
        settings.parameters = [selectableWorkParameter.number];
        return;
      }

      if (selectableParameters.length) {
        [settings.parameter] = selectableParameters;
      }
    },
  },
  mounted() {
    const {
      edited, expanded, devices, deviceGroups,
      byGroups, parameters, interval,
    } = this.monitor.params || {
      devices: [],
      deviceGroups: [],
      byGroups: false,
      interval: 'hour',
      parameters: [],
      edited: this.edited,
      expanded: this.expanded,
    };

    if (devices.length) {
      this.settings.devices = this.devices
        .filter((device) => devices.includes(device.id))
        .map((device) => device.id);
    }

    if (byGroups) {
      this.settings.byGroups = byGroups;
    }

    if (deviceGroups.length) {
      this.settings.deviceGroups = this.deviceGroups
        .filter((deviceGroup) => deviceGroups.includes(deviceGroup.id))
        .map((deviceGroup) => deviceGroup.id);
    }

    if (parameters.length) {
      this.settings.parameters = parameters;
    }

    if (interval) {
      this.settings.interval = intervals.find((i) => i.id === interval);
    }

    this.settings.edited = edited;
    this.settings.expanded = expanded;
    this.onOptions(edited);
  },
  methods: {
    getActiveAndWorkParametr(parameters) {
      const active = this.selectableActiveParameter;
      const work = this.selectableWorkParameter;
      return {
        work: parameters.find((p) => p.number === work.number),
        active: parameters.find((p) => p.number === active.number),
      };
    },
    async fetchData() {
      this.render = false;
      const {
        echart, date, settings, selectedDevices,
      } = this;
      const { interval, dateRange } = settings;
      const devices = selectedDevices.map((device) => device.id);

      const from = format(dateRange[0], date.format);
      const to = format(dateRange[1], date.format);
      const url = '/devices/diagnostics/multiple';

      this.loading = true;

      const response = await this.$http.get(url, {
        devices: devices.join(','),
        parameterNumber: settings.parameters.includes(0)
          ? this.selectedEfficiencyParameter.join(',') : settings.parameters.join(','),
        interval: interval.id,
        from,
        to,
      });

      if (response.status === 404) {
        this.loading = false;
        settings.parameters = [];
      }

      const { data } = response.data;

      // Detect if interval was changed by backend
      if (data.interval !== interval.id) {
        this.settings.interval = intervals.find((i) => i.id === data.interval);
      }

      echart.yAxis = [];

      const tooltips = [];

      this.units = [];

      if (settings.parameters[0] === 0) {
        this.getChartForEfficiency(data, echart, settings, tooltips);
      } else {
        this.getChart(data, echart, settings, tooltips);
      }
    },
    async getChartForEfficiency(data, echart, settings, tooltips) {
      const efficiencyUnit = { short: '%', long: 'Percent' };
      const firstUnit = efficiencyUnit;

      const axis = {
        type: 'value',
        axisLabel: {
          formatter: null,
        },
        offset: 0,
        axisLine: {
          show: false,
          lineStyle: {
            color: colors[0 % colors.length][500],
          },
        },
        splitNumber: 5,
        min: null,
        max: null,
        interval: null,
        minInterval: null,
      };

      const unit = {
        short: firstUnit.short || null,
        long: firstUnit.long || null,
        original_short: firstUnit.short || null,
        original_long: firstUnit.long || null,
        min: firstUnit.min || null,
        max: firstUnit.max || null,
      };

      const tooltip = {
        formatter: null,
        confine: true,
      };

      // Default tooltip formatter
      tooltip.formatter = (params) => {
        let buffer;
        let title;

        if (Array.isArray(params)) {
          buffer = params
            .map(({ marker, seriesName, value }) => `${marker} ${seriesName}: ${value}%`)
            .join('<br>');
          title = params[0].name;
        } else {
          const { marker, seriesName, value } = params;
          buffer = `${marker} ${seriesName}: ${value}%`;
          title = params.name;
        }

        return `${title}<br>${buffer}`;
      };

      // Default yAxis label formatter
      axis.axisLabel.formatter = (value) => `${value} ${unit.short}`;

      // Default xAxis label formatter
      echart.xAxis.axisLabel.formatter = (value) => {
        const formatted = format(parse(value), settings.interval.trans)
          .replace(' ', '\n')
          .toLowerCase();
        const translated = formatted.replace(/datetime\.[a-z.]+/g, (match) => t`${match}`);
        return translated;
      };

      if (unit.short === '%') {
        axis.splitNumber = 10;
        axis.min = unit.min || 0;
        axis.max = unit.max || 100;
      }

      axis.unit = unit.original_short;
      echart.yAxis.push(axis);

      tooltips.push(tooltip);
      this.units.push(unit);

      // data
      this.analyzeYAxis();

      await this.$sleep(100);

      echart.series = [];
      echart.legend.data = [];
      let colorIndex = 0;
      data.data.forEach((payload) => {
        const { work, active } = this.getActiveAndWorkParametr(payload.parameters);
        const { name } = payload.device;
        let seriesName = '';
        active.values.forEach(
          // eslint-disable-next-line no-return-assign
          (value, activeIndex) => work.values[activeIndex] = parseInt(
            work.values[activeIndex] / value * 100, 10,
          ) || 0,
        );
        seriesName = `${name}`;
        const series = {
          name: seriesName,
          type: 'bar',
          connectNulls: true,
          data: work.values,
          tooltip: tooltips[0],
          yAxisIndex: this.getAxis(unit.short),
          itemStyle: {
            color: colors[colorIndex % colors.length]['500'],
          },
        };
        echart.series.push(series);
        echart.legend.data.push(seriesName);
        colorIndex += 1;
      });
      // Create categories
      this.echart.xAxis.data = data.categories;
      this.data = data.data;

      this.render = true;
      this.loading = false;
    },
    async getChart(data, echart, settings, tooltips) {
      data.data.forEach(({ parameters }) => {
        parameters.forEach((units, unitsIndex) => {
          const firstUnit = units.unit;
          let highestValue = 0;
          let divider = 1;

          const axis = {
            type: 'value',
            axisLabel: {
              formatter: null,
            },
            offset: unitsIndex < 2 ? 0 : (unitsIndex - 1) * 50,
            axisLine: {
              show: false,
              lineStyle: {
                color: colors[unitsIndex % colors.length][500],
              },
            },
            splitNumber: 5,
            min: null,
            max: null,
            interval: null,
            minInterval: null,
          };

          const unit = {
            short: firstUnit.short || null,
            long: firstUnit.long || null,
            original_short: firstUnit.short || null,
            original_long: firstUnit.long || null,
            min: firstUnit.min || null,
            max: firstUnit.max || null,
          };

          const tooltip = {
            formatter: null,
            confine: true,
          };

          // Default tooltip formatter
          tooltip.formatter = (params) => {
            let buffer;
            let title;

            if (Array.isArray(params)) {
              buffer = params
                .map(({ marker, seriesName, value }) => `${marker} ${seriesName}: ${Math.round(value * 100) / 100}${unit.short}`)
                .join('<br>');
              title = params[0].name;
            } else {
              const { marker, seriesName, value } = params;
              buffer = `${marker} ${seriesName}: ${Math.round(value * 100) / 100}${unit.short}`;
              title = params.name;
            }

            return `${title}<br>${buffer}`;
          };

          // Default yAxis label formatter
          axis.axisLabel.formatter = (value) => `${value} ${unit.short}`;

          // Default xAxis label formatter
          echart.xAxis.axisLabel.formatter = (value) => {
            const formatted = format(parse(value), settings.interval.trans)
              .replace(' ', '\n')
              .toLowerCase();
            const translated = formatted.replace(/datetime\.[a-z.]+/g, (match) => t`${match}`);
            return translated;
          };

          if (unit.short === '%') {
            axis.splitNumber = 10;
            axis.min = unit.min || 0;
            axis.max = unit.max || 100;
          }

          if (unit.short === 'on|off') {
            axis.splitNumber = 1;
            axis.min = unit.min || 0;
            axis.max = unit.max || 1;
            echart.tooltip.formatter = (params) => {
              let buffer;
              let title;

              if (Array.isArray(params)) {
                buffer = params
                  .map(({ marker, seriesName, value }) => `${marker} ${seriesName}: ${Math.round(value)}`)
                  .join('<br>');
                title = params[0].name;
              } else {
                const { marker, seriesName, value } = params;
                buffer = `${marker} ${seriesName}: ${Math.round(value)}`;
                title = params.name;
              }

              return `${title}<br>${buffer}`;
            };
            axis.axisLabel.formatter = (tick) => (tick ? 'On' : 'Off');
          }

          if (unit.short === 's') {
            if (data.interval === 'shifts') {
              data.data.forEach((payload) => {
                const dailyValues = repeat(0, data.categories.length);
                payload.shifts.forEach((shift) => {
                  shift.values.forEach((value, index) => {
                    dailyValues[index] += Number(value);
                  });
                });
                highestValue = max(highestValue, (reduce(max, 0, dailyValues)));
              });
            } else {
              units.values.forEach((value) => {
                highestValue = Math.max(highestValue, value);
              });
            }
            if (highestValue === 0) {
              divider = 1;
              unit.short = 's';
              unit.long = 'Seconds';
            }

            if (highestValue > 0) {
              divider = 1;
              unit.short = 's';
              unit.long = 'Seconds';
            }

            if (highestValue > 60) {
              divider = 60;
              unit.short = 'min';
              unit.long = 'Minutes';
            }

            if (highestValue > 3600) {
              divider = 3600;
              unit.short = 'h';
              unit.long = 'Hours';
            }

            const segments = highestValue / divider;
            const scale = Math.ceil(segments > 10 ? segments / 10 : 1) || 1;
            const axisInterval = divider * scale;
            const maxValue = (Math.ceil(segments / scale) * scale) * divider;

            axis.interval = axisInterval;
            axis.minInterval = axisInterval;
            axis.max = maxValue;

            axis.axisLabel.formatter = (value) => `${value / divider} ${unit.short}`;

            tooltip.formatter = (params) => {
              let buffer;
              let title;

              if (Array.isArray(params)) {
                buffer = params
                  .map(({ marker, seriesName, value }) => `${marker} ${seriesName}: ${human(value, { maxHours: true })}`)
                  .join('<br>');
                title = params[0].name;
              } else {
                const { marker, value } = params;
                let { seriesName } = params;

                if (seriesName === null) {
                  seriesName = data.data[params.seriesIndex].device.name;
                }

                buffer = `${marker} ${seriesName}: ${human(value, { maxHours: true })}`;
                if (Array.isArray(value)) {
                  buffer = `${marker} ${seriesName}: ${human(value[1], { maxHours: true })}`;
                }
                title = params.name;
              }
              return `${title}<br>${buffer}`;
            };
          }
          axis.unit = unit.original_short;
          echart.yAxis.push(axis);

          tooltips.push(tooltip);
          this.units.push(unit);
        });
      });

      // tu koniec unitsow
      this.analyzeYAxis();

      await this.$sleep(100);

      echart.series = [];
      echart.legend.data = [];

      let colorIndex = 0;
      // dane
      data.data.forEach((payload, index) => {
        if (data.interval === 'shifts') {
          const series = [];
          payload.shifts.forEach((shift, index2) => {
            const name = `${payload.device.name}, ${this.unit.shift} ${index2 + 1}`;
            const deviceSeries = {
              name,
              stack: payload.device.id,
              type: 'bar',
              connectNulls: true,
              data: [],
              itemStyle: {
                color: colors[index % colors.length][swatches[index2 % swatches.length]],
              },
            };
            shift.values.forEach((value, indexCat) => {
              deviceSeries.data.push([shift.cat[indexCat], value]);
            });
            echart.series.push(deviceSeries);
            echart.legend.data.push(name);
            series.push(deviceSeries);
          });

          series[0].data.forEach((el) => {
            let sum = 0;
            series.forEach((shift) => {
              shift.data.forEach((value) => {
                if (el[0] === value[0]) {
                  sum += value[1];
                }
              });
            });
            if (echart.yAxis.max < sum) {
              echart.yAxis.max = Math.ceil(sum / 3600) * 3600;
            }
          });
        } else {
          const { name } = payload.device;
          let seriesName = '';

          payload.parameters.forEach((param, index3) => {
            seriesName = `${name} - ${param.name}`;
            const series = {
              name: seriesName,
              type: 'bar',
              connectNulls: true,
              data: param.values,
              tooltip: tooltips[index3] ? tooltips[index3] : {},
              yAxisIndex: this.getAxis(param.unit.short),
              itemStyle: {
                color: colors[colorIndex % colors.length]['500'],
              },
            };
            echart.series.push(series);
            echart.legend.data.push(seriesName);
            colorIndex += 1;
          });
        }
      });
      // Create categories
      this.echart.xAxis.data = data.categories;
      this.data = data.data;

      this.render = true;
      this.loading = false;
    },
    remove() {
      this.$emit('remove', this.monitor);
    },
    onOptions(edited) {
      this.settings.edited = edited;
      this.echart.toolbox.show = edited;
      this.echart.legend.top = edited ? 40 : 0;
      this.echart.grid.top = edited ? 80 : 40;
    },
    async refresh() {
      if (this.settingsValid) {
        await this.fetchData();
      }
    },
    async resize(width = null) {
      const { chart } = this.$refs;
      // Delay process of resizing chart
      // by animation time of resizing widget
      await this.$sleep(300);

      if (chart && this.settingsValid) {
        chart.resize({
          width: width || 'auto',
        });
      }
    },
    toCSV() {
      const labels = ['DateTime'];
      const { series, xAxis } = this.echart;
      const dateTimes = xAxis.data;

      series.forEach((serie) => {
        const unit = this.units[serie.yAxisIndex].original_short;
        labels.push(`${serie.name} (${unit})`);
      });

      const push = (value, list) => {
        list.push(value);
      };

      const rows = dateTimes.map((dateTime, index) => {
        const data = [dateTime];

        series.forEach((serie) => {
          push(Number(serie.data[index]).toFixed(2), data);
        });

        return data;
      });

      csv(rows, labels);
    },
    saveSettings() {
      const { monitor, settings } = this;
      const {
        devices, deviceGroups, byGroups,
        interval, parameters, expanded, edited,
      } = settings;

      monitor.params = {
        devices,
        deviceGroups,
        byGroups,
        parameters,
        interval: interval ? interval.id : null,
        edited,
        expanded,
      };

      this.$emit('save');
    },
    getAxis(unit) {
      return findIndex(propEq('unit', unit))(this.echart.yAxis);
    },
    analyzeYAxis() {
      const newYAxis = [];
      const { yAxis } = this.echart;

      yAxis.forEach((axis) => {
        const { unit } = axis;
        const index = findIndex(propEq('unit', unit))(newYAxis);

        if (index === -1) {
          newYAxis.push(axis);
        } else {
          const newMax = Math.max(newYAxis[index].max, axis.max);
          const newInterval = Math.max(newYAxis[index].interval, axis.interval);
          newYAxis[index].max = newMax;
          newYAxis[index].interval = newInterval;
        }
      });

      this.echart.yAxis = newYAxis;
    },
  },
};
</script>

<style scoped lang="stylus" src="./EfficiencyMonitor.styl"></style>

<style scoped lang="stylus">
.progress
  z-index: 10
  position: absolute
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
</style>
