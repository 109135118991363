import { get as getApi } from '@services/api';
import flatten from '@utils/flatten';
import router, { pages } from '../../router';

const mutations = {
  ROUTES_Add: 'ROUTES_Add',
};

/**
 * Module responsible for managing application routes.
 */
export default {
  namespaced: true,
  state: {
    routes: [],
  },
  getters: {
    routes: (s) => s.routes,
  },
  mutations: {
    [mutations.ROUTES_Add](state, routes) {
      state.routes.push(...flatten(routes, 'children'));
      router.addRoutes(routes);
    },
  },
  actions: {
    async loadRoutes({ commit }) {
      const map = new Map();
      const routes = [];
      const api = getApi();
      const { data, status } = await api.get('/routes');

      if (status !== 200) {
        return;
      }

      data.routes.push(
        {
          name: 'login',
          path: '/login',
          title: 'Login',
          component: 'LogoutForm',
          menu: false,
          login: true,
        },
        {
          name: 'dashboard',
          path: '/dashboard',
          title: 'menu.dashboard',
          component: 'Dashboard',
          icon: 'home',
          menu: true,
        },
      );

      data.routes.forEach((record) => {
        const route = {
          name: record.name,
          component: pages[record.component],
          path: record.path,
          // Automagically pass route params as props to component
          props: ({ params }) => {
            const props = {};
            // If id is defined, cast it to number
            if (params.id) {
              props.id = Number(params.id);
            }

            return props;
          },
          children: [],
          meta: {
            login: record.login === true,
            title: record.title,
            redirect: !!record.redirect,
            root: record.parent === undefined,
            menu: record.menu === true,
            icon: record.icon,
          },
        };

        if (record.redirect) {
          route.redirect = { name: record.redirect };
        }

        if (record.parent) {
          map.get(record.parent).children.push(route);
        }

        map.set(route.name, route);
      });

      routes.push(...[...map.values()].filter((r) => r.meta.root));
      commit(mutations.ROUTES_Add, routes);
    },
    async loadRoutesLogin({ commit }) {
      const map = new Map();
      const routes = [];

      const loginRoutes = [
        {
          name: 'default',
          path: '/',
          redirect: 'login',
        },
        {
          name: 'login',
          path: '/login',
          component: 'LoginForm',
          title: 'Login',
          menu: false,
          login: true,
        },
        {
          name: 'reset',
          path: '/reset',
          title: 'reset',
          component: 'ResetForm',
          login: true,
        },
        {
          name: 'resetting',
          path: '/resetting',
          component: 'ResettingForm',
          login: true,
        },
        {
          name: 'terms',
          path: '/terms',
          component: 'Terms',
          login: true,
        },
        {
          name: 'terms_en',
          path: '/terms_en',
          component: 'TermsEn',
          login: true,
        },
        {
          name: 'dashboard',
          path: '/dashboard',
          title: 'menu.dashboard',
          component: 'Dashboard',
          icon: 'home',
          menu: true,
        },
      ];

      loginRoutes.forEach((record) => {
        const route = {
          name: record.name,
          component: pages[record.component],
          path: record.path,
          // Automagically pass route params as props to component
          props: ({ params }) => {
            const props = {};
            // If id is defined, cast it to number
            if (params.id) {
              props.id = Number(params.id);
            }

            return props;
          },
          children: [],
          meta: {
            login: record.login === true,
            title: record.title,
            redirect: !!record.redirect,
            root: record.parent === undefined,
            menu: record.menu === true,
            icon: record.icon,
          },
        };

        if (record.redirect) {
          route.redirect = { name: record.redirect };
        }

        if (record.parent) {
          map.get(record.parent).children.push(route);
        }

        map.set(route.name, route);
      });

      routes.push(...[...map.values()].filter((r) => r.meta.root));
      commit(mutations.ROUTES_Add, routes);
    },
  },
};
