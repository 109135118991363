<template>
  <div>
    <transition
      name="page-switch"
      mode="out-in"
    >
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-3"
      >
        <bkf-widget class="pb-4">
          <v-row>
            <v-col>
              <field-device-groups
                v-model="settings.deviceGroups"
                :device-groups="deviceGroups"
              />
            </v-col>
            <v-col>
              <field-date-range
                v-model="settings.date.range"
                class="mr-20"
              />
            </v-col>
            <v-col>
              <field-interval
                v-model="settings.interval"
                :intervals="intervals"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                :disabled="!settingsValid || fetching"
                color="primary"
                tile
                class="ml-4 mt-2"
                @click="generate"
              >
                {{ 'report.action.generate'|trans }}
              </v-btn>
              <v-btn
                :disabled="fetching || report.length === 0"
                color="primary"
                tile
                class="mt-2"
                @click="toCSV"
              >
                {{ 'report.action.export'|trans }}
              </v-btn>
            </v-col>
          </v-row>
          <div
            v-if="message"
            class="message"
          >
            <v-alert
              type="warning"
              class="mt-0"
            >
              {{ message }}
            </v-alert>
          </div>
          <efficiency-table
            :headers="tableHeaders"
            :device-groups="report"
            :interval="interval"
          />
        </bkf-widget>
      </bkf-page>
      <router-view v-else />
    </transition>
  </div>
</template>

<script>
import { replace } from 'ramda';
import startOfToday from 'date-fns/start_of_today';
import subDays from 'date-fns/sub_days';
import format from 'date-fns/format';
import csv from '@utils/csv';
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import sizeMixin from '@mixins/store/size';
import EfficiencyTable from './EfficiencyTable.vue';
import FieldDateRange from './fields/DateRange';
import FieldDeviceGroups from './fields/DeviceGroups';
import FieldInterval from './fields/Interval';

export default {
  components: {
    EfficiencyTable,
    FieldDateRange,
    FieldDeviceGroups,
    FieldInterval,
  },
  mixins: [
    pageMixin,
    listMixin,
    sizeMixin,
  ],
  data() {
    return {
      fetching: false,
      fetched: false,
      report: [],
      deviceGroups: [],
      tableHeaders: [],
      interval: null,
      intervals: [{
        id: 'day',
        name: t`efficiency-report.interval.day`,
      }, {
        id: 'month',
        name: t`efficiency-report.interval.month`,
      }, {
        id: 'shifts',
        name: t`shifts.shift`,
      },
      ],
      settings: {
        deviceGroups: [],
        interval: null,
        date: {
          format: 'YYYY-MM-DD',
          formatShift: 'YYYY-MM-DD HH:mm',
          range: [
            subDays(startOfToday(), 31),
            startOfToday(),
          ],
        },
      },
    };
  },
  computed: {
    settingsValid() {
      const { deviceGroups, interval } = this.settings;

      if (deviceGroups.length === 0) {
        return false;
      }

      if (interval === null) {
        return false;
      }

      return true;
    },
    message() {
      const { deviceGroups, interval } = this.settings;

      if (deviceGroups.length === 0) {
        return t`report.message.no-device-groups-selected`;
      }

      if (interval === null) {
        return t`report.message.no-interval-selected`;
      }

      if (this.fetched && this.report.length === 0 && this.settings.interval === 'shifts') {
        return t`report.message.no-shifts-set`;
      }

      if (this.fetched && this.report.length === 0) {
        return t`report.message.no-data-found`;
      }

      return null;
    },
  },
  methods: {
    async generate() {
      await this.fetchData();
    },
    async onActivatePage() {
      const DeviceGroup = await this.$model('DeviceGroup');
      const { entries: deviceGroups } = await DeviceGroup.query({
        sort: { name: 'asc' },
      });
      this.deviceGroups = deviceGroups;
    },
    async fetchData() {
      const { date, deviceGroups, interval } = this.settings;
      const url = '/reports/device_efficiency';
      this.fetching = true;
      this.fetched = false;

      const { data, status } = await this.$http.get(url, {
        from: format(date.range[0], date.format),
        to: format(date.range[1], date.format),
        deviceGroups: deviceGroups.map((dg) => dg.id).join(','),
        requestId: this.requestId,
        interval,
      });

      if (status === 204) {
        this.report = [];
        this.fetched = true;
      }

      if (status === 200) {
        this.report = data;
        this.tableHeaders = [];
        this.interval = this.settings.interval;
        data.forEach((deviceGroup) => {
          deviceGroup.devices.forEach((device) => {
            if (device.efficiency.length && !this.tableHeaders.length) {
              device.efficiency.forEach((efficiency) => {
                if (!this.interval === 'shifts') {
                  this.tableHeaders.push(format(efficiency.date, date.format));
                } else {
                  this.tableHeaders.push(format(efficiency.date, date.formatShift));
                }
              });
            }
          });
        });

        this.fetched = true;
      }

      this.fetching = false;
    },
    toCSV() {
      const deviceGroups = this.report;
      const { interval, date } = this.settings;
      const rows = [];

      if (interval === 'year') {
        date.format = 'YYYY';
      } else if (interval === 'month') {
        date.format = 'YYYY-MM';
      } else if (interval === 'shifts') {
        date.format = 'YYYY-MM-DD HH:mm';
      } else {
        date.format = 'YYYY-MM-DD';
      }

      const headers = [
        t`efficiency-report.device-group`,
        t`efficiency-report.device`,
        t`device-efficiency-report.active-time`,
        t`device-efficiency-report.work-time`,
        t`device-efficiency-report.report-counter`,
        t`report.field.unit`,
      ];

      this.tableHeaders.forEach((headerDate) => {
        headers.push(`${replace(/-/g, '.', headerDate)} ${t`alert-rule.units.Percent`}`);
        headers.push(`${replace(/-/g, '.', headerDate)} ${t`report.field.counter`}`);
      });

      deviceGroups.forEach((deviceGroup) => {
        deviceGroup.devices.forEach((device) => {
          const row = [
            deviceGroup.name,
            device.name,
          ];
          row.push(device.summaryActive);
          row.push(device.summaryWork);
          row.push(device.reportCounter ? device.reportCounter : '');
          row.push('%');
          device.efficiency.forEach((efficiency) => {
            const value = efficiency.efficiency ? `${efficiency.efficiency}` : '0';
            row.push(value);
            row.push(efficiency.counter);
          });

          rows.push(row);
        });

        return rows;
      });

      const title = t`report.list.efficiency-report.title`;

      csv(
        rows,
        headers,
        `${title} ${format(new Date(), 'YYYY-MM-DD H:mm')}\n\n`,
        { filename: `efficiency-report-${format(date.range[0], date.format)}-${format(date.range[1], date.format)}` },
      );
    },
  },
};
</script>
