import Vue from 'vue';
import * as Sentry from '@sentry/vue';
// Import app component
import Application from '@components/application/Application';

/**
 * Factory for creating application
 */
export default {
  /**
   * Create new Application instance
   */
  create: ({
    router, store, vuetify,
  }) => {
    Sentry.init({
      Vue,
      dsn: 'https://8a96fc0326ae420f83e50cf85ac6ac54@o440445.ingest.sentry.io/4504672555499520',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    // Return new instance of application
    return new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      async mounted() {
        if (this.checkLogin()) {
          // Initialize store modules and wait for results
          await this.$store.dispatch('initLogin', { apiClient: this.$http, config: CONFIG });
        } else {
          // Initialize store modules and wait for results
          await this.$store.dispatch('init', { apiClient: this.$http, config: CONFIG });
        }
      },
      methods: {
        checkLogin() {
          const token1 = localStorage.getItem('fm-oauth');
          return (token1 === null);
        },
      },
      render: (h) => h(Application),
    });
  },
};
