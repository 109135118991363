import Add from './Add';
import Monitor from './Monitor';
import DeviceOperatorMonitor from './DeviceOperatorMonitor';
import DeviceWorkOperatorMonitor from './DeviceWorkOperatorMonitor';
import GeoMonitor from './GeoMonitor';
import EfficiencyMonitor from './EfficiencyMonitor';
import AsyncMonitor from './AsyncMonitor';
import DeviceWorkShiftMonitor from './DeviceWorkShiftMonitor';

export default {
  install(Vue) {
    Vue.use(Add);
    Vue.use(Monitor);
    Vue.use(DeviceOperatorMonitor);
    Vue.use(DeviceWorkOperatorMonitor);
    Vue.use(GeoMonitor);
    Vue.use(EfficiencyMonitor);
    Vue.use(AsyncMonitor);
    Vue.use(DeviceWorkShiftMonitor);
  },
};
