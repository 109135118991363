<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="550"
    eager
    transform-origin="bottom center"
    @input="v => v || submit()"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'dashboard.configure-dashboard'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click.stop="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <v-card-subtitle>
          {{ 'dashboard.settings.widgets.show'|trans }}
        </v-card-subtitle>
        <v-list
          subheader
          flat
        >
          <v-list-item-group
            v-model="settings"
            multiple
          >
            <v-list-item value="activity-summary">
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'dashboard.settings.widgets.activity-summary'|trans }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item value="efficiency-summary">
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'dashboard.settings.widgets.efficiency-summary'|trans }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item value="operator-summary">
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'dashboard.settings.widgets.operator-summary'|trans }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item value="attention">
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'dashboard.settings.widgets.attention'|trans }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item value="work-efficiency">
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'dashboard.settings.widgets.effective-work'|trans }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item value="device-shift-work">
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ 'dashboard.settings.widgets.device-shift-work'|trans }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          @click="close"
        >
          {{ 'dialog.close'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      settings: [],
      showDialog: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(to) {
        this.settings = to;
      },
    },
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
      this.submit();
    },
    submit() {
      this.$emit('submit', this.settings);
    },
  },
};
</script>
