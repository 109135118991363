import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"550","eager":"","transform-origin":"bottom center"},on:{"input":function (v) { return v || _vm.submit(); }},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("trans")('dashboard.configure-dashboard')))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"pt-4 flex-grow-1"},[_c(VCardSubtitle,[_vm._v("\n        "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.show'))+"\n      ")]),_vm._v(" "),_c(VList,{attrs:{"subheader":"","flat":""}},[_c(VListItemGroup,{attrs:{"multiple":""},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}},[_c(VListItem,{attrs:{"value":"activity-summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.activity-summary'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c(VListItem,{attrs:{"value":"efficiency-summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.efficiency-summary'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c(VListItem,{attrs:{"value":"operator-summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.operator-summary'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c(VListItem,{attrs:{"value":"attention"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.attention'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c(VListItem,{attrs:{"value":"work-efficiency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.effective-work'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c(VListItem,{attrs:{"value":"device-shift-work"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.device-shift-work'))+"\n                ")])],1)]}}])})],1)],1)],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"tile":"","color":"primary"},on:{"click":_vm.close}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('dialog.close'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }