<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <v-btn
          tile
          color="primary"
          @click.native="create"
        >
          {{ 'device-group.create'|trans }}
        </v-btn>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-3"
      >
        <bkf-widget v-if="deviceGroups.length">
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0"
            >
              <bkf-exporter
                ref="exporter"
                :data="deviceGroups"
                :columns="columns"
                filename="device-groups"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="deviceGroups"
            :item-class="rowClass"
            :items-per-page.sync="settings.size"
            :search="search"
            @click:row="handleClick"
          >
            <template #[`item.diagnostics.active`]="{ item }">
              {{ getGroupActiveTime(item)|human }}
            </template>
            <template #[`item.diagnostics.work`]="{ item }">
              {{ getGroupWorkTime(item)|human }}
            </template>
            <template #[`item.diagnostics.productivity`]="{ item }">
              {{ item.diagnostics.productivity }}%
            </template>
            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="edit(item)" />
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state v-else />
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device-group.create')"
      />
      <bkf-page-settings :settings.sync="settings" />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import BkfExporter from '@components/exporter';
import sizeMixin from '@mixins/store/size';
import { mapGetters } from 'vuex';

export default {
  components: {
    BkfExporter,
  },
  mixins: [
    pageMixin,
    listMixin,
    settingsMixin,
    sizeMixin,
  ],
  data() {
    return {
      search: '',
      settings: {
        size: 10,
      },
      deviceGroups: [],
      columns: [{
        value: 'name',
        text: t`device-group.name`,
      },
      {
        value: 'devices.length',
        text: t`device-group.devices-number`,
      },
      {
        value: 'diagnostics.active',
        text: t`device-group.today-activity`,
      },
      {
        value: 'diagnostics.work',
        text: t`device-group.today-work`,
      },
      {
        value: 'diagnostics.productivity',
        text: t`device-group.today-productivity`,
      },
      {
        value: 'actions',
        align: 'right',
        sortable: false,
        filterable: false,
        exportable: false,
      }],
    };
  },
  computed: {
    ...mapGetters({
      devicesGroupDiagnostics: 'devices/devicesGroupDiagnostics',
    }),
  },
  methods: {
    async onActivatePage() {
      await this.init({
        modelName: 'DeviceGroup',
      });

      const tmpDeviceGroups = [];

      this.deviceGroups.forEach((deviceGroup) => {
        const diagnostics = {
          active: this.averageGroupActivity(deviceGroup.id, deviceGroup.devices.length),
          work: this.averageGroupWork(deviceGroup.id, deviceGroup.devices.length),
          productivity: this.groupProductivity(deviceGroup.id, deviceGroup.devices.length),
        };
        deviceGroup.diagnostics = diagnostics;

        tmpDeviceGroups.push(deviceGroup);
      });

      this.deviceGroups = tmpDeviceGroups;
    },
    averageGroupActivity(id, devicesCount) {
      if (!this.devicesGroupDiagnostics(id)) {
        return 0;
      }
      return devicesCount !== 0 ? (this.devicesGroupDiagnostics(id).active / devicesCount) : 0;
    },
    averageGroupWork(id, devicesCount) {
      if (!this.devicesGroupDiagnostics(id)) {
        return 0;
      }
      return devicesCount !== 0 ? (this.devicesGroupDiagnostics(id).work / devicesCount) : 0;
    },
    groupProductivity(id, devicesCount) {
      const activity = this.averageGroupActivity(id, devicesCount);

      if (activity === 0) {
        return 0;
      }

      return Math.round(this.averageGroupWork(id, devicesCount) / activity * 100);
    },
    getGroupActiveTime(row) {
      return row?.diagnostics?.active;
    },
    getGroupWorkTime(row) {
      return row?.diagnostics?.work;
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device-group', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>
