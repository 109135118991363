export default {
  methods: {
    translatedParameterName(parameter) {
      if (parameter.name === undefined) {
        return false;
      }

      return parameter.name;
    },
    translateParameterNameByTag(tags) {
      if (tags.includes('effective work time')) {
        return t`parameter.work`;
      }
      if (tags.includes('device active')) {
        return t`parameter.active`;
      }

      if (tags.includes('efficiency')) {
        return t`parameter.efficiency`;
      }

      return tags[0];
    },
  },
};
