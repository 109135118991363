<template>
  <div class="mt-20">
    <bkf-widget>
      <template slot="title">
        {{ 'report.cyclical'|trans }}
      </template>
      <template slot="actions">
        <v-btn
          tile
          color="primary"
          @click.native="configurator"
        >
          {{ 'report.add'|trans }}
        </v-btn>
      </template>
      <v-data-table
        v-if="reportsConfigurations.length"
        :headers="columns"
        :items="reportsConfigurations"
        :items-per-page="settings.size"
      >
        <template #[`item.reportType`]="{ item }">
          {{ `report.list.` + item.reportType + `.title`|trans }}
        </template>
        <template #[`item.reportInterval`]="{ item }">
          {{ `el.interval.` + item.reportInterval|trans }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-row>
            <v-spacer />
            <v-col
              cols="2"
              class="pr-0"
            >
              <v-tooltip
                bottom
                :activator="$refs['switch_' + item.id]"
              >
                <span>{{ switchTooltip(item) }}</span>
              </v-tooltip>
              <v-switch
                :ref="'switch_' + item.id"
                v-model="item.isEnabled"
                dense
                hide-details
                class="mt-1 w40"
                @click="setEnabledFlag(item)"
              />
            </v-col>
            <v-col
              cols="4"
              class="pl-0"
            >
              <bkf-action-edit
                @click="edit(item)"
              />
              <bkf-action-delete
                @click="remove(item)"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <bkf-empty-state
        v-else
        :show-widget="false"
        :title="noReports"
      />
    </bkf-widget>
    <bkf-crud
      ref="crud"
      :create-submit-label="$trans('report.action.create')"
      :update-title="$trans('report.action.edit')"
      :delete-title="$trans('report.action.delete')"
      :reports-type="reportsType"
    />
    <bkf-page-settings
      :settings.sync="settings"
    />
  </div>
</template>

<script>
import listMixin from '@mixins/page/list';
import pageMixin from '@mixins/page';
import settingsMixin from '@mixins/misc/settings';

export default {
  mixins: [
    listMixin,
    pageMixin,
    settingsMixin,
  ],
  props: {
    reportsType: {
      required: true,
      type: Array,
    },
    models: {
      required: false,
      type: Function,
      default: null,
    },
    reportsConfigurations: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      settings: {
        page: 1,
        size: 10,
      },
      noReports: t`report.no-reports`,
      model: this.models,
      columns: [
        {
          value: 'name',
          text: t`report.field.name`,
        },
        {
          value: 'reportInterval',
          text: t`report.field.interval`,
        },
        {
          value: 'reportType',
          text: t`report.field.types`,
        },
        {
          value: 'actions',
          align: 'right',
          sortable: false,
          filterable: false,
        },
      ],
      config: {
        multiSelect: false,
        multiSort: false,
        sortCaseSensitive: false,
      },
    };
  },
  methods: {
    async setEnabledFlag(row) {
      const { id, enabled } = row;
      const action = enabled ? 'off' : 'on';
      this.powerPending = true;
      const { status } = await this.$http.patch(`/reportsconfigurations/${id}/${action}`);

      if (status === 204) {
        this.$notify.success(enabled ? 'report.disabled' : 'report.enabled');
      }
    },
    switchTooltip(row) {
      if (row.enabled) {
        return this.$trans('report.action.tooltip-active');
      }
      return this.$trans('report.action.tooltip-deactivate');
    },
  },
};
</script>
