<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      width="300"
    >
      <v-list
        nav
        expand
        dense
        flat
        class="main-menu"
      >
        <v-list-item
          :to="{name: 'dashboard'}"
          class="menu-height"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-body-2 font-weight-regular">
            {{ 'menu.dashboard'|trans }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'diagnostics'}">
          <v-list-item-icon>
            <v-icon>mdi-chart-timeline-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-body-2 font-weight-regular">
            {{ 'menu.monitor'|trans }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'location'}">
          <v-list-item-icon>
            <v-icon>mdi-map</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-body-2 font-weight-regular">
            {{ 'menu.devices-localization'|trans }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-group
          prepend-icon="mdi-bell"
          no-action
          :value="true"
        >
          <template #activator>
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.alerts'|trans }}
            </v-list-item-title>
          </template>
          <v-list-item :to="{name: 'alerts-list'}">
            <v-badge
              :content="alertsCount"
              :value="alertsCount"
              color="primary"
              inline
              class="badge-safari"
            >
              <v-list-item-title class="text-body-2 font-weight-regular">
                {{ 'menu.alert-list'|trans }}
              </v-list-item-title>
            </v-badge>
          </v-list-item>
          <v-list-item :to="{name: 'alert-rules'}">
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.alert-rules'|trans }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider />
        <v-list-item :to="{name: 'reports'}">
          <v-list-item-icon>
            <v-icon>mdi-text-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-body-2 font-weight-regular">
            {{ 'menu.reports'|trans }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-group
          prepend-icon="mdi-cog"
          no-action
          :value="true"
          class="nav-section-manage"
        >
          <template #activator>
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.manage'|trans }}
            </v-list-item-title>
          </template>
          <v-list-item :to="{name: 'devices'}">
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.devices'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'device-groups'}">
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.device-groups'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'device-operators'}">
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.device-operators'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'device-operator-groups'}">
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.device-operator-groups'|trans }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'users'}">
            <v-list-item-title class="text-body-2 font-weight-regular">
              {{ 'menu.users'|trans }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider />
        <v-list-item :to="{name: 'production-view'}">
          <v-list-item-icon>
            <v-icon>mdi-eye</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-body-2 font-weight-regular">
            {{ 'menu.production-view'|trans }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="openSupportDialog">
          <v-list-item-icon>
            <v-icon>mdi-email-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-body-2 font-weight-regular">
            {{ 'menu.support'|trans }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <template v-if="user.extras.isSuperAdmin">
          <v-list-group
            prepend-icon="mdi-cog"
            no-action
            :value="true"
            class="nav-section-manage"
          >
            <template #activator>
              <v-list-item-title class="text-body-2 font-weight-regular">
                {{ 'menu.admin'|trans }}
              </v-list-item-title>
            </template>
            <v-list-item
              :to="{name: 'administration-devices'}"
            >
              <v-list-item-title class="text-body-2 font-weight-regular">
                {{ 'menu.devices'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{name: 'administration-sensors'}"
            >
              <v-list-item-title class="text-body-2 font-weight-regular">
                {{ 'menu.sensors'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{name: 'administration-users'}"
            >
              <v-list-item-title class="text-body-2 font-weight-regular">
                {{ 'menu.users'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{name: 'administration-user-groups'}"
            >
              <v-list-item-title class="text-body-2 font-weight-regular">
                {{ 'menu.user-groups'|trans }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{name: 'administration-devices-types'}"
            >
              <v-list-item-title class="text-body-2 font-weight-regular">
                {{ 'menu.device-types'|trans }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
      <template v-if="config.brand.name === 'rejs'">
        <div
          class="center-align mt-20 clickable"
          @click="openURL('https://www.rywal.com.pl')"
        >
          <p>{{ 'menu.brand-owner'|trans }}</p>
          <img
            src="@assets/images/rejs/rejs.png"
            alt="rejs"
            width="200"
          >
        </div>
      </template>
    </v-navigation-drawer>
    <support-dialog
      :show-dialog="showDialog"
      @open-support-dialog="openSupportDialog"
      @close-support-dialog="closeSupportDialog"
      @submit-support-dialog="submitSupportDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import configMixin from '@mixins/store/config';
import userMixin from '@mixins/store/user';
import SupportDialog from '@components/dialogs/support/SupportDialog';

export default {
  components: {
    SupportDialog,
  },
  mixins: [
    configMixin,
    userMixin,
  ],
  data() {
    return {
      drawer: true,
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      alertsCount: 'alerts/total',
    }),
  },
  methods: {
    toggleMenu() {
      this.drawer = !this.drawer;
    },
    openSupportDialog() {
      this.showDialog = true;
    },
    closeSupportDialog() {
      this.showDialog = false;
    },
    async submitSupportDialog(values) {
      await this.$http.post('/support/mail', values);
    },
  },
};
</script>

<style>
.badge-safari span.v-badge__wrapper {
  height: inherit !important;
}
</style>
<style lang="stylus" src="./NavMenu.styl"></style>
