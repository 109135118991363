<template>
  <div class="device-operator">
    <portal
      v-if="isActivePage"
      to="page-actions"
    >
      <bkf-redirect
        v-if="!$vuetify.breakpoint.mobile"
        :options="deviceOperators"
        placeholder="device-operator.go-to-operator"
      />
    </portal>
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-3"
    >
      <div
        v-if="operator"
        class="device-operator-page flex flex-wrap"
      >
        <bkf-widget>
          <template slot="title">
            {{ 'device-operator.details'|trans }}
          </template>
          <template slot="actions">
            <bkf-action-edit @click="edit" /><bkf-action-delete
              :tooltip="$trans('action-button.assign')"
              @click="remove"
            />
          </template>
          <v-list dense>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.full-name'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ operator.name }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.position'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ operator|deep('userPosition.name') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.rfid'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ operator.rfid || '-' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.phone'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ operator.phone || '-' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.supervisor'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ operator|deep('supervisor.name') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.device-operator-group'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                <bkf-action-launch
                  v-for="(group, index) in operator.worker_groups"
                  :key="index"
                  tooltip="device-operator.go-to-group"
                  @click="redirectToOperatorsGroup(group.id)"
                >
                  {{ group.name }}
                </bkf-action-launch>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.company-name'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ operator.companyName || '-' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'sensor.last-active'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ operator|deep('userStatus.lastActive')|dateTime }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'device-operator.login-on-device'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                <bkf-action-launch
                  tooltip="device.go-to-device"
                  @click="redirectToDevice(operator|deep('userStatus.lastDevice.id'))"
                >
                  {{ operator|deep('userStatus.lastDevice.name') }}
                </bkf-action-launch>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </bkf-widget>
        <work-history
          :device-operator="entry"
          class="ml-3"
        />
        <bkf-widget
          v-if="!$vuetify.breakpoint.mobile"
          class="mt-3"
        >
          <v-tabs>
            <v-tab>
              {{ 'device-operator.worked-time'|trans }}
            </v-tab>
            <v-tab-item>
              <work
                v-if="deviceOperator"
                :device-operator="deviceOperator"
              />
            </v-tab-item>
            <v-tab>
              {{ 'device.diagnostics'|trans }}
            </v-tab>
            <v-tab-item>
              <bkf-device-operator-monitor
                v-if="deviceOperators"
                id="device_operator_monitor"
                :show-title="false"
                :show-actions="false"
                :show-device-operators="false"
                :width="tabWidth - 100"
                :device-operators="deviceOperators"
                :device-operators-groups="deviceOperator.worker_groups"
                :monitor="monitor"
              />
            </v-tab-item>
            <v-tab>
              {{ 'device-operator.assigned-devices'|trans }}
            </v-tab>
            <v-tab-item>
              <assigned-devices
                :device-operator="entry"
                :devices-bus="devicesBus"
              />
            </v-tab-item>
          </v-tabs>
        </bkf-widget>
      </div>
    </bkf-page>
    <bkf-crud
      ref="crud"
      @updated="updated"
    >
      <delete
        slot="delete"
        :devices-bus="devicesBus"
        @deleted="deleted"
        @deleteError="onError"
        @fetchError="onError"
      />
    </bkf-crud>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import detailsMixin from '@mixins/page/details';
import pageMixin from '@mixins/page';
import propsMixin from '@mixins/router/props';
import AssignedDevices from './tabs/AssignedDevices.vue';
import Work from './tabs/Work.vue';
import Delete from './Delete.vue';
import WorkHistory from './fields/WorkHistory.vue';

export default {
  components: {
    AssignedDevices,
    Delete,
    Work,
    WorkHistory,
  },
  mixins: [
    detailsMixin,
    pageMixin,
    propsMixin,
  ],
  data() {
    return {
      model: null,
      operator: null,
      entry: null,
      linkedEntry: {},
      deviceOperator: null,
      devicesBus: new Vue(),
      tabWidth: 100,
    };
  },
  computed: {
    ...mapGetters({
      deviceOperators: 'deviceoperators/deviceOperators',
    }),
    monitor() {
      // Fake monitor object for Monitor component
      return {
        id: 0,
        type: 'work',
        params: {
          deviceOperators: [this.deviceOperator.id],
          deviceOperatorsGroups: [this.deviceOperator.worker_groups],
          parameter: {
            number: 1,
          },
          edited: true,
          expanded: true,
        },
        save() {},
      };
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http.get(
        `/user/user/${this.$route.params.id}`,
      )
        .then(
          (response) => {
            this.operator = response.data;

            this.loading = false;
          },
        );
      this.loading = false;
    },
    async onActivatePage() {
      this.model = await this.$model('DeviceOperator');

      this.entry = await this.model.get({
        id: Number(this.$route.params.id),
        contexts: ['deviceoperator_details'],
      });

      this.deviceOperator = this.entry.$data;
    },
    onRowClick({ id }) {
      this.$router.push({ name: 'device', params: { id } });
    },
    async deleted() {
      this.$router.push({ name: 'device-operators' });
      this.$notify.success(t`device-operator.deleted`);
    },
    onError({ response }) {
      if (response.status === 404) {
        this.$notify.error('form.error.not-found');
      } else {
        this.$notify.error('form.error.internal-error');
      }
    },
    redirectToOperatorsGroup(id) {
      this.$router.push({ name: 'device-operator-group', params: { id } });
    },
    redirectToDevice(deviceId) {
      this.$router.push({ name: 'device', params: { id: deviceId } });
    },
  },
};
</script>

<style lang="stylus" scoped>
#device_operator_monitor
  box-shadow: none
.widget.mobileWidget
  flex-basis: 100%
</style>
