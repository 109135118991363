<template>
  <div
    :style="{
      'height': `${computeHeight}px`
    }"
  >
    <chart
      v-if="render"
      ref="chart"
      :options="getChartOptions"
      :autoresize="true"
      @click="redirectToDevice"
    />
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/tooltip';
import {
  descend, prop, sortWith, reverse, propEq, findIndex,
} from 'ramda';
import human from '@utils/human';
import trans from '@utils/trans';
import size from '@mixins/store/size';

export default {
  components: {
    chart: ECharts,
  },
  mixins: [size],
  props: {
    data: {
      type: Array,
      required: true,
    },
    refKey: {
      type: String,
      required: false,
      default: 'chart',
    },
    counters: {
      type: Array,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      render: false,
      echart: {
        grid: {
          left: '2%',
          right: '2%',
          bottom: 0,
          top: 45,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [
          {
            show: false,
            type: 'value',
            max: 'dataMax',
          },
          {
            show: false,
            type: 'value',
            max: 'dataMax',
          },
        ],
        yAxis: {
          triggerEvent: true,
          type: 'category',
          data: [],
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [],
        animationDuration: 300,
        legend: {
          show: true,
          top: 0,
          selected: {
          },
        },
      },
      show: {
        devicesWork: true,
        averageDevicesWork: true,
        devicesActive: true,
        averageDevicesActive: true,
        percentEfficiency: true,
        percentActivity: true,
      },
      activeColor: '#bcbcbc',
      counterColor: '#5fd15c',
      activeAverageColor: '#6f6f6f',
      workingColor: '#61a5f4',
      workingAverageColor: '#1070df',
    };
  },
  computed: {
    computeHeight() {
      return this.data.length * 50 + 45;
    },
    getChartOptions() {
      const { echart } = this;
      const xAxis = [];
      const xAxis2 = [];
      echart.yAxis.data = [];
      echart.series = [];
      const active = [];
      const work = [];
      const counters = [];
      const efficiency = [];
      const selected = {};

      const { show } = this.settings;
      const { percentEfficiency, percentActivity } = show;

      const sortWork = sortWith([descend(prop('workSum'))]);
      const sortActive = sortWith([descend(prop('activeSum'))]);

      if (this.data) {
        let sorted = [];

        if (this.settings.sort.by === 'work') {
          sorted = (sortWork(this.data));
        }

        if (this.settings.sort.by === 'active') {
          sorted = (sortActive(this.data));
        }

        if (this.settings.sort.dir === 'desc') {
          sorted = reverse(sorted);
        }

        sorted.forEach((element) => {
          let { name } = element;
          if (this.isMobile) {
            name = element.name.length > 15 ? `${element.name.substring(0, 15)}...` : element.name;
          }
          echart.yAxis.data.push(
            {
              id: element.id,
              value: name,
            },
          );
          active.push(element.activeSum);
          work.push(element.workSum);
          efficiency.push(element.activeSum ? ((element.workSum / element.activeSum) * 100) : 0);
          const getCounterForDevice = (countersArray) => {
            const index = findIndex(propEq('id', element.id), countersArray);

            if (index !== -1) {
              return countersArray[index].counter;
            }

            return 0;
          };

          counters.push(getCounterForDevice(this.counters));
        });

        echart.xAxis[0].data = xAxis;
        echart.xAxis[1].data = xAxis2;

        echart.tooltip.formatter = (params) => {
          let buffer = '';
          params.forEach(({
            marker, seriesName, value, dataIndex, componentIndex,
          }) => {
            if (componentIndex === 0) {
              const percentage = percentActivity ? this.getActivity(efficiency, dataIndex) : '';
              buffer += `${marker} ${seriesName}: ${human(value)} ${percentage}<br>`;
            }

            if (componentIndex === 1) {
              const percentage = percentEfficiency ? this.getEfficency(efficiency, dataIndex) : '';
              buffer += `${marker} ${seriesName}: ${human(value)} ${percentage}<br>`;
            }

            if (componentIndex === 2) {
              buffer += `${marker} ${seriesName}: ${value}<br>`;
            }
          });

          if (params[0].value === 0) {
            return null;
          }

          return `${params[0].name}<br>${buffer}`;
        };

        const activeFormatter = ({ value, dataIndex }) => {
          if (percentActivity) {
            return `${human(value)} ${this.getActivity(efficiency, dataIndex)}`;
          }
          return human(value);
        };

        const activeSeries = {
          name: trans('widget.operator-summary.active-time'),
          type: 'bar',
          data: active,
          color: this.activeColor,
          barGap: 0,
          barCategoryGap: 3,
          label: {
            normal: {
              show: true,
              position: 'insideLeft',
              formatter: activeFormatter,
            },
          },
          markLine: null,
        };

        if (this.settings.show.averageDevicesActive) {
          activeSeries.markLine = {
            symbol: ['none', 'none'],
            silent: true,
            data: [{
              type: 'average',
            }],
            lineStyle: {
              type: 'solid',
              color: this.activeAverageColor,
            },
            label: {
              normal: {
                show: true,
                position: 'end',
                formatter: ({ value }) => (value ? `${human(value)}` : ''),
              },
            },
          };
        }

        const workFormatter = ({ value, dataIndex }) => {
          if (this.settings.show.percentEfficiency) {
            return `${human(value)} ${this.getEfficency(efficiency, dataIndex)}`;
          }
          return human(value);
        };

        const workSeries = {
          name: trans('widget.operator-summary.work-time'),
          type: 'bar',
          data: work,
          color: this.workingColor,
          label: {
            normal: {
              show: true,
              position: 'insideLeft',
              formatter: workFormatter,
            },
          },
          markLine: null,
        };

        if (this.settings.show.averageDevicesWork) {
          workSeries.markLine = {
            symbol: ['none', 'none'],
            silent: true,
            data: [{
              type: 'average',
            }],
            lineStyle: {
              type: 'solid',
              color: this.workingAverageColor,
            },
            label: {
              normal: {
                show: true,
                position: 'end',
                formatter: ({ value }) => (value ? `${human(value)}` : ''),
              },
            },
          };
        }

        selected[`${trans('widget.operator-summary.active-time')}`] = true;
        selected[`${trans('widget.operator-summary.work-time')}`] = true;
        selected[`${trans('widget.operator-summary.device-counter')}`] = true;

        if (!this.settings.show.devicesActive) {
          selected[`${trans('widget.operator-summary.active-time')}`] = false;
        }

        if (!this.settings.show.devicesWork) {
          selected[`${trans('widget.operator-summary.work-time')}`] = false;
        }

        if (!this.settings.show.deviceCounter) {
          selected[`${trans('widget.operator-summary.device-counter')}`] = false;
        }

        echart.legend.selected = selected;
        echart.series.push(activeSeries);
        echart.series.push(workSeries);
      }

      return echart;
    },
  },
  created() {
    this.render = true;
  },
  methods: {
    getEfficency(efficiency, dataIndex) {
      if (efficiency[dataIndex] <= 100) {
        return `(${efficiency[dataIndex].toFixed(0)}%)`;
      }
      return '(-)';
    },
    getActivity(efficiency, dataIndex) {
      if (efficiency[dataIndex] <= 100) {
        return `(${100 - efficiency[dataIndex].toFixed(0)}%)`;
      }
      return '(-)';
    },
    redirectToDevice(params) {
      const { value, componentType } = params;

      if (componentType === 'yAxis') {
        const deviceId = this.findDeviceId(value);

        if (deviceId) {
          this.$router.push({ name: 'device', params: { id: deviceId } });
        }
      }
    },
    findDeviceId(name) {
      const search = this.data.filter((device) => {
        if (this.isMobile) {
          device.name = device.name.length > 15 ? `${device.name.substring(0, 15)}...` : device.name;
        }
        return device.name === name;
      });
      return search.length ? search[0].id : null;
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 100%;
  min-height: 100%;
}
</style>
